import { isServer } from 'solid-js/web';
import { BaseNetworkManager } from '~/gateways/network/baseNetworkManager';
import { MagicRequest } from '~/gateways/network/magicRequest';
import { NetworkManager } from '~/gateways/network/networkManager';

const matchUriWithPrefix = (uri: string) => {
  if (isServer) {
    if (import.meta.env.DEV) return 'http://localhost:3000' + uri;
    return `http://portal-backend` + uri.replace(/^\/api/, '/company-web');
  }
  return uri;
};

class FullStackNetworkManager implements NetworkManager {
  private networkManager: NetworkManager;
  constructor(networkManager: NetworkManager) {
    this.networkManager = networkManager;
  }

  public sendRequest = async (request: MagicRequest): Promise<Response> => {
    request.url = matchUriWithPrefix(request.url);
    return this.networkManager.sendRequest(request);
  };
}

export const fullStackNetworkManager = new FullStackNetworkManager(new BaseNetworkManager());
