import { HTTP_STATUS_CODE } from '~/assets/strings';

export class HttpError extends Error {
  private httpStatusCode: number;

  constructor(httpStatusCode: number, message: string) {
    super(HTTP_STATUS_CODE + httpStatusCode);
    this.httpStatusCode = httpStatusCode;
    this.message = message;
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  getStatusCode(): number {
    return this.httpStatusCode;
  }
}
