import { HttpError } from '~/errors/httpError';
import { NotFoundError } from '~/errors/notFoundError';
import { UnauthorizedError } from '~/errors/unauthorizedError';
import { MagicRequest } from './network/magicRequest';
import { NetworkManager } from './network/networkManager';
export class Gateway {
  protected networkManager: NetworkManager;

  constructor(networkManager: NetworkManager) {
    this.networkManager = networkManager;
  }

  protected sendRequest = async (request: MagicRequest): Promise<Response> => {
    const response = await this.networkManager.sendRequest(request);
    if (response.ok) {
      return response;
    } else {
      let json;
      try {
        json = await response.json();
      } catch {
        json = undefined;
      }
      throw this.createErrorForCode(response.status, json?.detail || '');
    }
  };

  protected createErrorForCode = (statusCode: number, message: string): Error => {
    if (statusCode === 401) {
      return new UnauthorizedError();
    }
    if (statusCode === 404) {
      return new NotFoundError();
    }
    return new HttpError(statusCode, message);
  };

  protected createCommonHeaders = (): Headers => {
    const headers: Headers = new Headers();
    headers.append('content-type', 'application/json;charset=UTF-8');
    return headers;
  };
}
